<template>
  <div id="pending_accounts">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('menu.pendingAccs') }}</h2>

          <p></p>
        </div>

        <div class="info_box">
          <ul>
            <li class="notes">
              <h3>{{ $t('pendingAccounts.NOTES') }}</h3>

              <p>{{ $t('pendingAccounts.errorDoc') }}</p>

              <br />

              <br />

              <p>{{ $t('pendingAccounts.acceptedDocType') }}</p>
            </li>

            <li>
              <div class="primary">
                <h3>{{ $t('pendingAccounts.primaryDoc') }}</h3>

                <ul>
                  <li v-for="(list, index) in $t('pendingAccounts.idList')" :key="index">{{ list }}</li>
                </ul>
              </div>
            </li>

            <li>
              <div class="secondary">
                <h3>{{ $t('pendingAccounts.address') }}</h3>

                <ul>
                  <li v-for="(list, index) in $t('pendingAccounts.addressList')" :key="index">{{ list }}</li>
                </ul>
              </div>
            </li>

            <li>
              <i18n path="pendingAccounts.tip" tag="p">
                <template v-slot:mailTo>
                  <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                </template>
              </i18n>
            </li>
          </ul>
        </div>

        <div class="table_content">
          <div class="table_box">
            <el-table :data="displayData" style="width: 100%">
              <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                <template slot-scope="scope">{{ scope.row.register_date | date }}</template>
              </el-table-column>

              <el-table-column prop="applyName" :label="$t('common.keys.NAME')" min-width="120"></el-table-column>

              <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="180"></el-table-column>

              <el-table-column :label="$t('common.keys.ACCTYPE')" min-width="120">
                <template slot-scope="scope">{{ $config.accountTypeMaps[scope.row.account_type] }}</template>
              </el-table-column>

              <el-table-column
                prop="mt4_datasource"
                :label="$t('common.keys.PLATFORM')"
                min-width="120"
              ></el-table-column>

              <el-table-column
                prop="apply_currency"
                :label="$t('common.keys.BASECURRENCY')"
                min-width="150"
              ></el-table-column>
            </el-table>
          </div>

          <Pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetPendingAccount } from '@/resource';
import Pagination from '@/components/Pagination';

export default {
  components: { Pagination },
  data() {
    return {
      // 分页
      userID: this.$store.state.common.CUID,
      total: 0,
      displayData: [],
      tableData: []
    };
  },
  mounted() {
    this.queryPendingUser();
  },
  methods: {
    queryPendingUser() {
      apiGetPendingAccount({
        pid: this.userID
      }).then(response => {
        if (response.data.code === 305) {
          this.tableData = [];
          this.total = 0;
        } else {
          this.tableData = response.data;
          this.total = this.tableData.length;
        }
      }, this.$handleError);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/pendingAccounts.scss';
</style>
